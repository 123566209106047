<template lang="pug">
  b-card.manager-account-contact-form.card-white.card-no-gutters.shadow(no-body)
    b-card-header.bg-transparent.border-0
      h5.m-0
        | {{ $t('contact.menus.my_contact') }}
    b-form.form-wc.form-account-contact-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-contact-phone(
              :label="$t('activerecord.attributes.contact.phone')"
              label-for='contact-phone'
              label-cols-md='2'
              label-align-md='right')
              b-form-row
                b-col(cols='5' sm='4' md='3' lg='2')
                  multiselect#contact-phone_country_code.form-input-first.shadow.custom-select(
                    name='phone_country_code'
                    v-model='contactPhoneCountryCodeSelected'
                    :options='contactPhoneCountryCodeOptions'
                    :class="[{ 'is-required' : !$v.form.data.attributes.phone_country_code.required }, formValidationClass($v.form.data.attributes.phone_country_code)]"
                    :close-on-select='true'
                    :clear-on-select='false'
                    placeholder='+00'
                    label='label'
                    track-by='value'
                    :custom-label="multiselectPhoneLabel"
                    :selectLabel="$t('shared.forms.multiselect.select_label')"
                    :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                    :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                    :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                    @input='formSelectOption')
                    template(slot='option', slot-scope='props')
                      span.h5.mb-0.mr-2
                        | {{ props.option.label.flag }}
                      span.mr-2
                        | {{ props.option.label.code }}
                      span.text-muted
                        | {{ props.option.label.name }}
                    template(slot='singleLabel' slot-scope='props')
                      span.mr-2
                        | {{ props.option.label.flag }}
                      span
                        | {{ props.option.label.code }}
                    span(slot='noResult')
                      | {{ $t('shared.forms.multiselect.no_result') }}
                    span(slot='noOptions')
                      | {{ $t('shared.forms.multiselect.no_options') }}
                  wc-forms-if#contact-phone_country_code-feedback(
                    extra-class='ml-feedback'
                    :attribute='$v.form.data.attributes.phone_country_code'
                    :remote="formRemoteInvalidFeedback('phone_country_code')"
                    :validators='{ required: {} }')

                b-col
                  b-form-input#contact-phone.form-input-first.shadow(
                    name='phone'
                    type='text'
                    autocomplete='phone'
                    :placeholder="$t('contact.placeholders.phone')"
                    :class="{ 'is-required' : !$v.form.data.attributes.phone.required }"
                    :state='formValidationState($v.form.data.attributes.phone)'
                    @focus.native='formChange'
                    v-model='$v.form.data.attributes.phone.$model'
                    aria-describedby='contact-phone-feedback'
                    trim)
                  wc-forms-if#contact-phone-feedback(
                    extra-class='ml-feedback'
                    :attribute='$v.form.data.attributes.phone'
                    :remote="formRemoteInvalidFeedback('phone')"
                    :validators='{ required: {}, regExp: {}, minLength: {}, maxLength: {} }')

            b-form-group#form-contact-mobile(
              :label="$t('activerecord.attributes.contact.mobile')"
              label-for='contact-mobile'
              label-cols-md='2'
              label-align-md='right')
              b-form-row
                b-col(cols='5' sm='4' md='3' lg='2')
                  multiselect#contact-mobile_country_code.form-input-middle.shadow.custom-select.is-required(
                    name='mobile_country_code'
                    v-model='contactMobileCountryCodeSelected'
                    :options='contactMobileCountryCodeOptions'
                    :class='formValidationClass($v.form.data.attributes.mobile_country_code)'
                    :close-on-select='true'
                    :clear-on-select='false'
                    placeholder='+00'
                    label='label'
                    track-by='value'
                    :custom-label="multiselectPhoneLabel"
                    :selectLabel="$t('shared.forms.multiselect.select_label')"
                    :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                    :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                    :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                    @input='formSelectOption')
                    template(slot='option', slot-scope='props')
                      span.h5.mb-0.mr-2
                        | {{ props.option.label.flag }}
                      span.mr-2
                        | {{ props.option.label.code }}
                      span.text-muted
                        | {{ props.option.label.name }}
                    template(slot='singleLabel' slot-scope='props')
                      span.mr-2
                        | {{ props.option.label.flag }}
                      span
                        | {{ props.option.label.code }}
                    span(slot='noResult')
                      | {{ $t('shared.forms.multiselect.no_result') }}
                    span(slot='noOptions')
                      | {{ $t('shared.forms.multiselect.no_options') }}
                  wc-forms-if#contact-mobile_country_code-feedback(
                    extra-class='ml-feedback'
                    :attribute='$v.form.data.attributes.mobile_country_code'
                    :remote="formRemoteInvalidFeedback('mobile_country_code')"
                    :validators='{ required: {} }')

                b-col
                  b-form-input#contact-mobile.form-input-middle.shadow.is-required(
                    name='mobile'
                    type='text'
                    autocomplete='mobile'
                    :placeholder="$t('contact.placeholders.mobile')"
                    :state='formValidationState($v.form.data.attributes.mobile)'
                    @focus.native='formChange'
                    v-model='$v.form.data.attributes.mobile.$model'
                    aria-describedby='contact-mobile-feedback'
                    trim)
                  wc-forms-if#contact-mobile-feedback(
                    extra-class='ml-feedback'
                    :attribute='$v.form.data.attributes.mobile'
                    :remote="formRemoteInvalidFeedback('mobile')"
                    :validators='{ required: {}, regExp: {}, minLength: {}, maxLength: {} }')

            b-form-group#form-contact-email(
              :label="$t('activerecord.attributes.contact.email')"
              label-for='contact-email'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#contact-email.form-input-last.shadow-sm.is-required(
                name='email'
                type='email'
                autocomplete='email'
                :placeholder="$t('contact.placeholders.email')"
                :state='formValidationState($v.form.data.attributes.email)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.email.$model'
                aria-describedby='contact-email-feedback'
                trim)
              b-form-text(v-if='!$v.form.data.attributes.email.$invalid')
                i.fal.fa-fw.fa-info-circle
                |  {{ $t('contact.helpers.email') }}
              wc-forms-if#contact-email-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.email'
                :remote="formRemoteInvalidFeedback('email')"
                :validators='{ required: {}, regExp: {}, maxLength: {} }')

            b-form-group(
              :label="$t('contact/link.labels.my_links')"
              label-cols-md='12'
              label-size='lg')

              b-row
                b-col(offset-md='2')
                  wc-icon-alert(
                    icon='fal fa-question-circle'
                    variant='success'
                    :title="$t('contact/link.alerts.no_links.title')"
                    :detail="$t('contact/link.alerts.no_links.detail')"
                    :active='!contactLinkNetworksInUse.length')

              template(v-for="(item, index) in linksAttributes")
                wc-forms-link-item(
                  :index='index'
                  :item.sync='item'
                  :validation.sync='$v.form.data.attributes.links_attributes[index]'
                  :form-errors.sync='form.errors'
                  :form-status.sync='form.status'
                  :form-input-pos='contactLinkFormInputPos(item.link_network_id)'
                  :link-network='contactLinkNetworksSelected(item)'
                  @remove-item='removeLink')

              b-form-group#form-contact-link-add.mt-4(
                :label="$t('contact/link.labels.add')"
                label-for='contact-link_networks'
                label-cols-md='2'
                label-align-md='right')
                b-row
                  b-col.col-lg-4
                    multiselect.shadow-sm.custom-select(
                      :id='`contact-link_networks`'
                      name='link_network'
                      v-model='form.helpers.link_network'
                      :options='contactLinkNetworkIdOptionsProcessed'
                      :close-on-select='true'
                      :clear-on-select='true'
                      :placeholder="$t('contact/link.placeholders.link_network')"
                      label='label'
                      track-by='value'
                      :searchable='false'
                      :selectLabel="$t('shared.forms.multiselect.select_label')"
                      :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                      :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                      :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                      @input='addLink')
                      span(slot='noResult')
                        | {{ $t('shared.forms.multiselect.no_result') }}
                      span(slot='noOptions')
                        | {{ $t('shared.forms.multiselect.no_options') }}

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-contact-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import WcIconAlert from '@components/shared/WcIconAlert'
import WcFormsLinkItem from '@components/shared/forms/WcFormsLinkItem'
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiContact from '@services/api/account/contact'
import { Form, mapSelects } from '@common/form'
import { FormLinks } from '@common/form/links'
import { regExp } from '@common/form/validations'
import { required, requiredIf, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'manager-account-contact-form',
  mixins: [Form, FormLinks],
  components: {
    WcIconAlert,
    WcFormsLinkItem,
    WcFormsButtons,
  },
  computed: {
    ...mapSelects([
      {
        name: 'contactPhoneCountryCode',
        default: 'attributes.phone_country_code',
        attribute: 'attributes.phone_country_code',
        collection: { data: 'countries.data', key: 'id', value: 'attributes.phone_label' },
      },
      {
        name: 'contactMobileCountryCode',
        default: 'attributes.mobile_country_code',
        attribute: 'attributes.mobile_country_code',
        collection: { data: 'countries.data', key: 'id', value: 'attributes.phone_label' },
      },
    ]),
    apiParams() {
      return {
        get: [],
        update: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
      }
    },
  },
  methods: {
    multiselectPhoneLabel({ label }) {
      return `${label.code} ${label.name}`
    },
  },
  validations() {
    let validations = {
      form: {
        data: {
          attributes: {
            phone_country_code: {
              required: requiredIf((attributes) => {
                /* Validate only if. */
                return this.validateIf('phone', ['regExp', 'minLength', 'maxLength'], {
                  if: 'invalid',
                })
                  ? false
                  : !!attributes.phone
              }),
              remote: () => this.formRemoteValid('phone_country_code'),
            },
            phone: {
              required: requiredIf((attributes) => {
                return !!attributes.phone_country_code && attributes.phone_country_code.length > 0
              }),
              regExp: regExp(this.$wc.conf.regex.phone_number_browser),
              minLength: minLength(this.$wc.conf.limits.min_phone),
              maxLength: maxLength(this.$wc.conf.limits.max_phone),
              remote: () => this.formRemoteValid('phone'),
            },
            mobile_country_code: {
              required,
              remote: () => this.formRemoteValid('mobile_country_code'),
            },
            mobile: {
              required,
              regExp: regExp(this.$wc.conf.regex.phone_number_browser),
              minLength: minLength(this.$wc.conf.limits.min_phone),
              maxLength: maxLength(this.$wc.conf.limits.max_phone),
              remote: () => this.formRemoteValid('mobile'),
            },
            email: {
              required,
              regExp: regExp(this.$wc.conf.regex.email_browser),
              maxLength: maxLength(this.$wc.conf.limits.max_email),
              remote: () => this.formRemoteValid('email'),
            },
            links_attributes: {},
          },
        },
      },
    }

    this.$getDeep(this.form.data, 'attributes.links_attributes').forEach((v, i) => {
      validations.form.data.attributes.links_attributes[i] = {}
      validations.form.data.attributes.links_attributes[i]['link_network_id'] = { required }
      validations.form.data.attributes.links_attributes[i]['value'] = {
        required: requiredIf(() => {
          return !v._destroy
        }),
        regExp: regExp(eval(this.contactLinkNetworksSelected(v).meta.validation || undefined)),
        maxLength: maxLength(this.$wc.conf.limits.max_link),
        remote: () => this.formRemoteValid(`links[${i}].value`),
      }
    })
    return validations
  },
  data() {
    return {
      apiBase: apiContact,
      apiModel: 'contact',
      skipFormFillNull: false,
      formBootstrapDefaults: {
        all: {
          helpers: {
            link_network: null,
          },
          data: {
            attributes: {
              links_attributes: [],
            },
          },
        },
      },
    }
  },
}
</script>
